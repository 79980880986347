import './App.scss';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ListMatch from "./pages/listmatch/ListMatch";
import DetailMatch from "./pages/detailmatch/DetailMatch";
import DetailCombattant from './pages/detailCombattant/DetailCombattant';
import ListCombatt from './pages/listCombattant/ListCombattant';
import Login from './Component/login/Login';
import Fighter from './pages/FighterPage/Fighter';


function App() {
  return (
    <Router>
      <div>
      <Routes>
        <Route exact path="/combat" element={ <DetailCombattant/> } />
        <Route exact path="/match" element={ <ListMatch/> } />
        <Route exact path="/detail" element={ <DetailMatch/> } />
        <Route exact path="/list" element={ <ListCombatt/> } />
        <Route exact path="/" element={ <Login/> } />
        <Route exact path="/fighter" element={ <Fighter/> } />
      </Routes>
      </div>
    </Router>
  );
}

export default App;
