import React, { useLayoutEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import SideBar from "../../Component/sidebar/SideBar";
import "./ListMatch.scss";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import ReactPaginate from "react-paginate";
import "./PaginationStyle.scss";
import UploadFile from "../../Component/uploadfile/UploadFile";
import vector from "../../assets/svg/Vector.svg";
import img from "../../assets/svg/import.svg";
import place from "../../assets/svg/place.svg";
import { iso1A2Code } from "@ideditor/country-coder";
import axiosService from "../../services/Axios/axiosServices";
import { API_FILE_UPLOAD, API_FILE_UPLOAD_MULTIPLE, API_ONE_PAGE_MATCH, SERVER_URL } from "../../api/entryPoint";
export default function ListMatch() {
  //pagination
  const [hits, setHits] = useState([]);
  const [pageCount, setPageCount] = useState(1); //New
  const [isLoaded, setisLoaded] = useState(false);
  const [currentPage, setcurrentPage] = useState(1); //New
  const [countItem, setCountItem] = useState(1);
  //pagination
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [disabled, setDisabled] = useState(false);
  const [multiple, setMultiple] = useState(false);
  const handleClose = () => {setShow(false);setMultiple(false)};

  const getList = async (currentPage) => {
    axiosService
      .get(SERVER_URL + API_ONE_PAGE_MATCH + currentPage)
      .then((response) => {
        setHits(response.data.results);
        setPageCount(response.data.total_pages);
        setCountItem(response.data.id_number);
        setisLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const postFile = async (data) => {
    let API_UPLOAD = multiple ? API_FILE_UPLOAD_MULTIPLE: API_FILE_UPLOAD
    axiosService
      .post(SERVER_URL + API_UPLOAD, data)
      .then((response) => {
        if(response.status === 201) {
          setDisabled(false)
          getList(currentPage);
          setShow(false)
          setMultiple(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const navigate = useNavigate();
  const detail = (id, pk) => {
    navigate("/detail", { state: { detail: id, match: pk } });
  };
  useLayoutEffect(() => {
    getList(currentPage);
  }, [currentPage]);
  const handlePageClick = (event) => {
    let page = event.selected;
    setcurrentPage(page + 1);
    getList(currentPage);
  };
  console.log(multiple ? API_FILE_UPLOAD_MULTIPLE: API_FILE_UPLOAD);
  function Match() {
    return (
      <>
        <div className="main">
          <div className="display-title">
            <div className="title-paginate">Liste des combats</div>
            <div className="display-flex-button">
            <div className="padding-right-div">
              <Button
                className="button-upload"
                onClick={()=>{handleShow();setMultiple(true)}}
                type="button"
              >
                <img src={img} alt="link" className="image-cursor" />
                <div className="text-button-upload">
                  Importer fichier du multiple combats
                </div>
              </Button>
            </div>
            <div>
              <Button
                className="button-upload"
                onClick={handleShow}
                type="button"
              >
                <img src={img} alt="link" className="image-cursor" />
                <div className="text-button-upload">
                  Importer votre fichier CSV
                </div>
              </Button>
            </div>
            </div>
          </div>
          <div>
            <Table responsive="lg md sm" className="lignesEspacees">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Année</th>
                  <th>Dévision</th>
                  <th>Catégorie</th>
                  <th>Match</th>
                  <th>Genre</th>
                  <th>Tour</th>
                  <th>Pays</th>
                </tr>
              </thead>
              <tbody>
                {hits.map((item, index) => {
                  return (
                    <tr key={index} className="td-style">
                      <td>{index + countItem}</td>
                      <td>{item.year}</td>
                      <td>{item.category_age}</td>
                      <td>{item.category_weight}</td>
                      <td className="width-td">
                        <ReactCountryFlag
                          countryCode={iso1A2Code(item.country_one)}
                          svg
                          className="width-td-vs"
                          title={item.country_one}
                        />
                        <span className="mr-1 m-l">{item.fighter_one}</span>
                        <span className="mr-1">vs</span>
                        <ReactCountryFlag
                          countryCode={iso1A2Code(item.country_two)}
                          svg
                          className="width-td-vs"
                          title={item.country_two}
                        />
                        <span className="m-l">{item.fighter_two}</span>
                      </td>
                      <td>
                        {" "}
                        <span className="gender">{item.gender}</span>{" "}
                      </td>
                      <td>{item.tour}</td>
                      <td className="size-place-td">
                        <img
                          src={place}
                          alt="link"
                          className="mr-5 place-icon"
                        />
                        <span>{item.competition_place}</span>
                      </td>
                      <td>
                        <img
                          src={vector}
                          alt="link"
                          onClick={() => {
                            detail(item.id, item.importfile);
                          }}
                          className="image-cursor"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {isLoaded ? (
              <div className="paginate">
                <ReactPaginate
                  pageCount={pageCount}
                  pageRange={2}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  disabledClassNae="disabled"
                  previousLabel="<"
                  nextLabel=">"
                />
              </div>
            ) : (
              <div>Nothing to display</div>
            )}
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <UploadFile
        show={show}
        handleClose={handleClose}
        postFile={postFile}
        setDisabled={setDisabled}
        disabled={disabled}
      ></UploadFile>
      <SideBar />
      {Match()}
    </>
  );
}
