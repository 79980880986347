import React, { useLayoutEffect, useState } from "react";
import { Table } from "react-bootstrap";
import SideBar from "../../Component/sidebar/SideBar";
import "../listmatch/ListMatch.scss";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import ReactPaginate from "react-paginate";
import "../listmatch/PaginationStyle.scss";
import vector from "../../assets/svg/Vector.svg";
import place from "../../assets/svg/place.svg";
import "./ListCombattant.scss";
import { iso1A2Code } from "@ideditor/country-coder";
import { API_FIGHTER, SERVER_URL } from "../../api/entryPoint";
import axiosService from "../../services/Axios/axiosServices";

export default function ListCombatt() {
  //pagination
  const [hits, setHits] = useState([]);
  const [pageCount, setPageCount] = useState(1); //New
  const [isLoaded, setisLoaded] = useState(false);
  const [currentPage, setcurrentPage] = useState(1); //New
  //pagination
  const getList = async (currentPage) => {
    axiosService
      .get(SERVER_URL + API_FIGHTER + currentPage)
      .then((response) => {
        setHits(response.data.results);
        setPageCount(response.data.total_pages);
        setisLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const navigate = useNavigate();
  const detail = (id, name) => {
    navigate("/fighter", { state: { id: id, name: name } });
  };
  useLayoutEffect(() => {
    getList(currentPage);
  }, [currentPage]);
  const handlePageClick = (event) => {
    let page = event.selected;
    setcurrentPage(page + 1);
    getList(currentPage);
  };
  function Match() {
    return (
      <>
        <div className="main">
          <div className="display-title">
            <div className="title-paginate">Liste des combattants</div>
          </div>
          <div>
            <Table responsive="lg md sm" className="lignesEspacees">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Année</th>
                  <th>Dévision</th>
                  <th>Catégorie</th>
                  <th>Prénom & Nom</th>
                  <th>Genre</th>
                  <th>Tour</th>
                  <th>Pays</th>
                </tr>
              </thead>
              <tbody>
                {hits.map((item, index) => {
                  return (
                    <>
                      <tr key={index} className="td-style">
                        <td>{index + 1}</td>
                        <td>{item.year}</td>
                        <td>{item.category_age}</td>
                        <td>{item.category_weight}</td>
                        <td className="width-td-combattant">
                          <ReactCountryFlag
                            countryCode={iso1A2Code(item.country)}
                            svg
                            style={{
                              width: "2em",
                              height: "2em",
                              borderRadius: "60px",
                            }}
                            title={item.country}
                          />
                          <span className="m-r m-l">{item.fighter}</span>
                        </td>
                        <td>{item.gender}</td>
                        <td>{item.tour}</td>
                        <td>
                          <img src={place} alt="link" className="mr-5" />
                          {item.competition_place}
                        </td>
                        <td>
                          <img
                            src={vector}
                            alt="link"
                            onClick={() => {
                              detail(item.id, item.fighter);
                            }}
                            className="image-cursor"
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
            {isLoaded ? (
              <div className="paginate">
                <ReactPaginate
                  pageCount={pageCount}
                  pageRange={2}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  disabledClassNae="disabled"
                  itemsPerPage={4}
                  previousLabel="<"
                  nextLabel=">"
                />
              </div>
            ) : (
              <div>Nothing to display</div>
            )}
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <SideBar />
      {Match()}
    </>
  );
}
