import React, { useLayoutEffect, useState } from "react";
import { Col, Image, Row, Table } from "react-bootstrap";
import retern from "../../assets/svg/retern.svg";
import photoProfile from "../../assets/png/Profile_photo.png";
import cercle from "../../assets/svg/image.svg";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../../Component/sidebar/SideBar";
import "./Fighter.scss";
import {
  API_SINGLE_FIGHTER,
  API_DETAIL_FIGHTER,
  SERVER_URL
} from "../../api/entryPoint";
import axiosService from "../../services/Axios/axiosServices";

export default function Fighter() {
  const [hits, setHits] = useState([]);
  const [point, setPoint] = useState([]);
  const [match, setMatch] = useState([]);
  const [detail, setDetail] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const getList = async () => {
    axiosService
      .get(SERVER_URL + API_SINGLE_FIGHTER + location.state.id)
      .then((response) => {
        setHits(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTech = async () => {
    const formData = new FormData();
    formData.append("params", location.state.name);
    axiosService
      .get(API_DETAIL_FIGHTER + API_DETAIL_FIGHTER + location.state.name)
      .then((response) => {
        setDetail(response.data);
        setPoint(response.data.data.point);
        setMatch(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const arrayPoint = () => {
    point.map((subarray) => {
      while (subarray.length < 30) {
        subarray.push("0");
      }
      return subarray;
    });
  };
  arrayPoint();
  /*eslint-disable */
  useLayoutEffect(() => {
    getList();
    getTech();
  }, []);
  /*eslint-enable */
  const returnBack = () => {
    navigate("/list");
  };
  const percent = (partiel, total) => {
    if (partiel === 0) {
      return 0;
    } else {
      return (100 * (partiel / total)).toFixed(2);
    }
  };
  function WinnerCombatt() {
    return (
      <div className="main">
        <div className="title-font d-flex">
          <Image
            src={retern}
            alt="return"
            className="mr-1 cursor-bottom"
            onClick={returnBack}
          ></Image>
          <p className="name-margin-top  mt-3">{hits.fighter}</p>
        </div>
        <Row className="d-flex-evently margin-top-20 mb-3">
          <Col className="detail-col">
            <div className="d-flex">
              <div className="m-r-25">
                <Image
                  src={photoProfile}
                  alt="profile"
                  className="mage-style1"
                ></Image>
              </div>
              <Col lg={3}>
                <div className="d-flex">
                  <div className="detail-div w-20 pb-2">Genre</div>
                  <div className="data-div pt-1">{hits.gender}</div>
                </div>
                <div className="d-flex">
                  <div className="detail-div w-20 pb-2">Pays</div>
                  <div className="data-div pt-1">{hits.country}</div>
                </div>
                <div className="d-flex">
                  <div className="detail-div w-20 pb-2">Référence</div>
                  <div className="data-div pt-1">Référence</div>
                </div>
                <div className="d-flex">
                  <div className="detail-div w-20">Catégorie de Poids</div>
                  <div className="data-div pt-1">{hits.category_weight}</div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="d-flex ">
                  <div className="detail-div pb-2">Nb de combats</div>
                  <div className="data-div pt-1">{detail.number_match}</div>
                </div>
                <div className="d-flex ">
                  <div className="detail-div pb-2">Gagnés</div>
                  <div className="data-div pt-1">{detail.winner_match}</div>
                </div>
                <div className="d-flex ">
                  <div className="detail-div pb-2">Perdus</div>
                  <div className="data-div pt-1">{detail.looser_match}</div>
                </div>
                <div className="d-flex">
                  <div className="detail-div">Efficacité</div>
                  <div className="data-div pt-1">
                    {percent(detail?.winner_match, detail?.number_match)}%
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        <Row className="d-flex-evently margin-top-20">
          <Col lg={4} className="detail-col">
            <div>
              <div className="font-title-cercle color-green">ATTAQUES</div>
              <div className="d-flex">
                <Col>
                  <div>
                    <div>Avant gauche</div>
                    <div className="d-flex">
                      <div className="cercle-green"></div>
                      <div></div>
                    </div>
                    <div className="d-flex">
                      <div className="cercle-red"></div>
                      <div></div>
                    </div>
                    <div className="d-flex">
                      <div className="cercle-green"></div>
                      <div></div>
                    </div>
                  </div>
                  <div>
                    <div>Arriére gauche</div>
                    <div className="d-flex">
                      <div className="cercle-green"></div>
                      <div></div>
                    </div>
                    <div className="d-flex">
                      <div className="cercle-red"></div>
                      <div></div>
                    </div>
                    <div className="d-flex">
                      <div className="cercle-green"></div>
                      <div></div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <Image src={cercle} alt="cercle" className="cercle"></Image>
                </Col>
                <Col>
                  <div>
                    <div>Avant droit</div>
                    <div className="d-flex">
                      <div className="cercle-green"></div>
                      <div></div>
                    </div>
                    <div className="d-flex">
                      <div className="cercle-red"></div>
                      <div></div>
                    </div>
                    <div className="d-flex">
                      <div className="cercle-green"></div>
                      <div></div>
                    </div>
                  </div>
                  <div>
                    <div>Arriére droit</div>
                    <div className="d-flex">
                      <div className="cercle-green"></div>
                      <div></div>
                    </div>
                    <div className="d-flex">
                      <div className="cercle-red"></div>
                      <div></div>
                    </div>
                    <div className="d-flex">
                      <div className="cercle-green"></div>
                      <div></div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </Col>
          <Col lg={4} className="detail-col">
            <div className="font-title-cercle color-red">EN DANGER</div>
            <div className="d-flex">
              <Col>
                <div>
                  <div>Avant gauche</div>
                  <div className="d-flex">
                    <div className="cercle-green"></div>
                    <div></div>
                  </div>
                  <div className="d-flex">
                    <div className="cercle-red"></div>
                    <div></div>
                  </div>
                  <div className="d-flex">
                    <div className="cercle-green"></div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <div>Arriére gauche</div>
                  <div className="d-flex">
                    <div className="cercle-green"></div>
                    <div></div>
                  </div>
                  <div className="d-flex">
                    <div className="cercle-red"></div>
                    <div></div>
                  </div>
                  <div className="d-flex">
                    <div className="cercle-green"></div>
                    <div></div>
                  </div>
                </div>
              </Col>
              <Col>
                <Image src={cercle} alt="cercle" className="cercle"></Image>
              </Col>
              <Col>
                <div>
                  <div>Avant droit</div>
                  <div className="d-flex">
                    <div className="cercle-green"></div>
                    <div></div>
                  </div>
                  <div className="d-flex">
                    <div className="cercle-red"></div>
                    <div></div>
                  </div>
                  <div className="d-flex">
                    <div className="cercle-green"></div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <div>Arriére droit</div>
                  <div className="d-flex">
                    <div className="cercle-green"></div>
                    <div></div>
                  </div>
                  <div className="d-flex">
                    <div className="cercle-red"></div>
                    <div></div>
                  </div>
                  <div className="d-flex">
                    <div className="cercle-green"></div>
                    <div></div>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        <Row className="margin-top-20">
          <Table className="width-table">
            <thead>
              <tr>
                <th>Adversaire</th>
                <th>Resultat</th>
                <th className="th-width-table"></th>
                <th>TS</th>
                <th>k</th>
                <th>++</th>
                <th>+</th>
              </tr>
            </thead>
            <tbody>
              {point
                ? point.map(function (subarray, index) {
                    return (
                      <tr key={index} className="custom_td">
                        <td>
                          {match.fighters[index].losing_fighter
                            ? hits.fighter.includes(
                                match.fighters[index].winner_fighter
                              )
                            : match.fighters[index].winner_fighter}{" "}
                        </td>
                        <td>
                          {match.fighters[index].losing_fighter ===
                          hits.fighter ? (
                            <div>Perdu</div>
                          ) : match.fighters[index].winner_fighter ===
                            hits.fighter ? (
                            <div>Gagné</div>
                          ) : null}
                        </td>
                        <td className="display-flex-1">
                          {subarray &&
                            subarray.map((item) => {
                              return item === "0.0" ||
                                item === "0" ||
                                item === "nan" ? (
                                <div className="white-color"></div>
                              ) : item === "1.0" ||
                                item === "1" ||
                                item === "10" ||
                                item === "10.0" ? (
                                <div className="green-color"></div>
                              ) : item === "Kinsa" ? (
                                <div className="gray-color"></div>
                              ) : (
                                <div></div>
                              );
                            })}
                        </td>
                        <td>{match ? match.length_array[index] : null}</td>
                        <td className="color-kinsa">
                          {match ? match.frequency[index].aKinsa : null}
                        </td>
                        <td className="color-red-text">
                          {match ? match.frequency[index].a10 : null}
                        </td>
                        <td className="color-green-text ">
                          {match ? match.frequency[index].a1 : null}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>
        </Row>
      </div>
    );
  }

  return (
    <>
      <SideBar></SideBar>
      {WinnerCombatt()}
    </>
  );
}
