import { API_LOGIN, SERVER_URL } from "../api/entryPoint";
import axiosService from "../services/Axios/axiosServices";

export const loginAuth = async (data, navigate) => {
  axiosService
    .post(SERVER_URL + API_LOGIN, data)
    .then((response) => {
      const Token = response.data.access;
      const Refresh = response.data.refresh;
      localStorage.setItem("token", Token);
      localStorage.setItem("refresh", Refresh);
      if (Token) navigate("/match");
    })
    .catch((err) => {
      console.log(err);
    });
};
