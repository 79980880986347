import axios from "axios";

// // Add a response interceptor
// axios.interceptors.response.use(
//     response => response,
//     error => {
//         // Reject promise if usual error
//         if (error.response.status !== 401) {
//             return Promise.reject(error);
//         }
//         if( localStorage.getItem('refresh_token')){
//             const url = API_HOST + API_REFRESH_TOKEN
//             axios.create().post(url, {'refresh_token': localStorage.getItem('refresh_token')}).then(response => {
//                     //get token
//                     const token = response.data.token;
//                     localStorage.setItem("token", token);
//                 },
//                 error => {
//                     localStorage.clear();
//                     window.location = "/login"
//                 }
//             )
//         }
//         return Promise.reject(error);
//     }
// )
axios.interceptors.response.use(
  (response) => {
    // block to handle success case
    return response;
  },
  async function (error) {
    // block to handle error case
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === "http://dummydomain.com/auth/token"
    ) {
      // Added this condition to avoid infinite loop
      // Redirect to any unauthorised route to avoid infinite loop...
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      // Code inside this block will refresh the auth token

      originalRequest._retry = true;
      const refreshToken = "xxxxxxxxxx"; // Write the  logic  or call here the function which is having the login to refresh the token.
      return axios
        .post("/auth/token", {
          refresh_token: refreshToken,
        })
        .then((res) => {
          if (res.status === 201) {
            localStorage.setItem("auth_token", res.data);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("auth_token");
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);
// Add a request interceptor
// axios.interceptors.request.use(
//     function (config) {

//         //get token
//         const token = localStorage.getItem("token");
//         // Do something before request is sent
//         config.headers.Authorization = `Bearer ${token}`;

//         return config;
//     },
//     function (error) {
//         // Do something with request error
//         return Promise.reject(error);
//     }
// );
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const axiosService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
export default axiosService;
