//server swagger link
export const SERVER_URL = "https://api.ffj.prod-projet.com/";
// api login 
export const API_LOGIN = "api/auth/login/";
//api list match paginate
export const API_LIST = "api/file/list/";
//api match with id
export const API_ONE_MATCH = "api/file/list/";
//api paginate match with page number
export const API_ONE_PAGE_MATCH = "api/file/list/?page=";
//apload file csv of match
export const API_FILE_UPLOAD = "api/file/upload/";
// apload file for multiple combat
export const API_FILE_UPLOAD_MULTIPLE = "api/file/upload/multiple/";
//api technique with id
export const API_ONE_TECH = "api/file/technique/";
//api techniques with list json
export const API_MULTI_TECH = "api/file/techniques";
//api FIGHTER with list json
export const API_FIGHTER = "api/file/fighter/?page=";
//api single FIGHTER 
export const API_SINGLE_FIGHTER = "api/file/fighter/";
//get fighter with params
export const API_DETAIL_FIGHTER = "api/file/detail/?params=";

//server swagger link
export const SERVER_URL_LOCAL = "http://127.0.0.1:8000/";
// api login 
export const API_LOGIN_LOCAL = "http://127.0.0.1:8000/api/auth/login/";
//api list match paginate
export const API_LIST_LOCAL = "http://127.0.0.1:8000/api/file/list/";
//api match with id
export const API_ONE_MATCH_LOCAL = "http://127.0.0.1:8000/api/file/list/";
//api paginate match with page number
export const API_ONE_PAGE_MATCH_LOCAL = "http://127.0.0.1:8000/api/file/list/?page=";
//apload file csv of match
export const API_FILE_UPLOAD_LOCAL = "http://127.0.0.1:8000/api/file/upload/";
//api technique with id
export const API_ONE_TECH_LOCAL = "http://127.0.0.1:8000/api/file/technique/";
//api techniques with list json
export const API_MULTI_TECH_LOCAL = "http://127.0.0.1:8000/api/file/techniques";
//api FIGHTER with list json
export const API_FIGHTER_LOCAL = "http://127.0.0.1:8000/api/file/fighter/?page=";
//api single FIGHTER 
export const API_SINGLE_FIGHTER_LOCAL = "http://127.0.0.1:8000/api/file/fighter/";
//get fighter with params
export const API_DETAIL_FIGHTER_LOCAL = "http://127.0.0.1:8000/api/file/detail/?params=";