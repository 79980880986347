import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

function Example({ show, handleClose, postFile, disabled, setDisabled }) {
  const [selectedFile, setSelectedFile] = useState();
  const [error, setError] = useState("");
  const allowedExtensions = ["csv"];
  const changeHandler = (e) => {
    setError("");
    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Svp importer que les fichiers csv");
        return;
      }
      // If input type is correct set the state
      setSelectedFile(inputFile);
    }
  };
  const handleSubmission = (event) => {
    event.preventDefault();
    setDisabled(true)
    const formData = new FormData();
    formData.append("File", selectedFile);
    postFile(formData);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmission}>
          <Modal.Header closeButton>
            <Modal.Title>Importer Votre Csv</Modal.Title>
          </Modal.Header>
          <Form.Group controlId="formFile" className="mb-3 mr-5">
            <Form.Label></Form.Label>
            <Form.Control type="file" name="file" onChange={changeHandler} />
          </Form.Group>
          <div className="color-red">{error ? error : null}</div>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={disabled}>
              {disabled ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </>
              ) : (
                "importer"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
export default Example;
