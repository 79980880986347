import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginAuth } from "../../action/action";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onSubmitForm = (e) => {
    e.preventDefault();
    const form = { email: email, password: password };
    loginAuth(form, navigate);
  };
  const formLogin = () => {
    return (
      <div className="login-form d-flex flex-column justify-content-center align-items-center">
        <form onSubmit={onSubmitForm}>
          <div className="title-login">Bienvenu dans france judo</div>
          <div>
            <label className="mb-2">Email d'utilisateur</label>
            <input
              type="email"
              name="email"
              className="form-control width-input margin-input-bottom"
              placeholder="Enter email"
              onChange={(e) => onChangeEmail(e)}
              value={email}
            />
          </div>
          <div>
            <label className="mb-2">Mot de passe</label>
            <input
              type="password"
              name="password"
              className="form-control width-input"
              placeholder="Enter password"
              onChange={(e) => onChangePassword(e)}
              value={password}
            />
          </div>
          <div>
            <button
              className="btn btn-primary button-connect width-input"
              onClick={onSubmitForm}
            >
              Se connecter
            </button>
          </div>
        </form>
      </div>
    );
  };
  return (
    <>
    <div className="login d-flex flex-column min-vh-100 justify-content-center align-items-center">
      {formLogin()}
    </div>
    </>
  );
}
