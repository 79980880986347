import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import "./SideBar.scss";
import combattant from "../../assets/svg/combattant.svg";
import combat from "../../assets/svg/combat.svg";

export default function SideBar() {
  const [active, setActive] = useState(0);
  return (
    <>
      <Nav
        className="col-md-12 d-none d-md-block bg-light sidebar"
        activeKey="/match"
        onSelect={(selectedKey) => setActive(selectedKey)}
      >
        <img
          src="https://www.ffjudo.com/assets/images/logo.png"
          alt="Fédération Française de Judo"
          className="judo-logo"
        ></img>
        <div className="sidebar-logo">
          <Nav.Item>
            <img alt="combattant" src={combattant}></img>
            <Nav.Link
              href="/match"
              eventKey={0}
            >
              Combat
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="is-active">
            <img
              alt="combattant"
              src={combat}
              className="margin-left-img"
            ></img>
            <Nav.Link
              href="/list"
              eventKey={1}
              className={active === 1 ? "is-active" : null}
            >
              Combattants
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="disabled" disabled></Nav.Link>
          </Nav.Item>
        </div>
      </Nav>
    </>
  );
}
