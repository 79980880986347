import { useLayoutEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import SideBar from "../../Component/sidebar/SideBar";
import { useLocation, useNavigate } from "react-router-dom";
import "./DetailMatch.scss";
import { iso1A2Code } from "@ideditor/country-coder";
import ReactCountryFlag from "react-country-flag";
import photoProfile from "../../assets/png/Profile_photo.png";
import photoProfile2 from "../../assets/png/Profile_photo2.png";
import vector from "../../assets/svg/Vector.svg";
import cercle from "../../assets/svg/image.svg";
import retern from "../../assets/svg/retern.svg";
import { API_ONE_MATCH, API_ONE_TECH, SERVER_URL } from "../../api/entryPoint";
import axiosService from "../../services/Axios/axiosServices";
export default function DetailMatch() {
  const [hits, setHits] = useState([]);
  const [point, setPoint] = useState([]);
  const [ids_loser, setLoser] = useState([]);
  const [id_winner, setWinner] = useState([]);
  const [detail, setDetail] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  var getInitials = (string) => {
    if (string) {
      var names = string.split(" "),
        initials = names[0].substring(0, 1).toUpperCase();
    }
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  const getList = async () => {
    axiosService
      .get(SERVER_URL +API_ONE_MATCH + location.state.detail)
      .then((response) => {
        setHits(response.data.combattant);
        setLoser(response.data.ids_loosing);
        setWinner(response.data.ids_winner);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTechnique = async () => {
    axiosService
      .get(SERVER_URL + API_ONE_TECH + location.state.match)
      .then((response) => {
        setPoint(response.data.point);
        setDetail(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const arrayPoint = () => {
    while (point.length < 30) {
      point.push("0");
    }
  };
  arrayPoint();
  /*eslint-disable */
  useLayoutEffect(() => {
    getList();
    getTechnique();
  }, []);
  /*eslint-enable */
  const returnBack = () => {
    navigate("/match");
  };
  const goToCOmbat = (status, ids) => {
    navigate("/combat", {
      state: {
        detail: location.state.detail,
        match: location.state.match,
        status: status,
        ids: ids,
      },
    });
  };
  const percent = (partiel, total) => {
    if (partiel === 0) {
      return 0;
    } else {
      return (100 * (partiel / total)).toFixed(2);
    }
  };
  //filter country of winner
  const country_winner = () => {
    if (hits?.fighter_one === hits?.winner_fighter) {
      return hits?.country_one
    }else {
      return hits?.country_two
    }
  }
  
  //filter country of looser
  const country_looser = () => {
    if (hits?.fighter_one === hits?.losing_fighter) {
      return hits?.country_one
    }else {
      return hits?.country_two
    }
  }
  const white_color = () => {
    let words = hits?.white
    if (words) {
      let words_split = words.split(' ')
      return words_split.slice(-1)[0]
    }
  } 
  function Detail() {
    return (
      <div className="main">
        <div className="title-font mb-3">
          <Image
            src={retern}
            alt="return"
            className="mr-1 cursor-bottom"
            onClick={returnBack}
          ></Image>
          Détail du combat
        </div>
        <div className="flex-name">
          <div className="name-title">
            <span>
              <ReactCountryFlag
                countryCode={iso1A2Code(country_looser())}
                svg
                style={{
                  width: "2em",
                  height: "2em",
                  borderRadius: "60px",
                }}
                title={hits.country_one}
              />
            </span>
            <span className="m-l">{hits.losing_fighter}</span>
          </div>
          <div className="name-title">0s1 - 10s2</div>
          <div className="name-title1">
            <span className="m-l">
              <ReactCountryFlag
                countryCode={iso1A2Code(country_winner())}
                svg
                style={{
                  width: "2em",
                  height: "2em",
                  borderRadius: "60px",
                }}
                title={hits.country_two}
              />
            </span>
            <span className="m-l">{hits.winner_fighter}</span>
            <span className="m-l">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="20"
                fill="currentColor"
                className="bi bi-trophy"
                viewBox="0 0 16 16"
              >
                <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935zM3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z" />
              </svg>
            </span>
          </div>
        </div>
        <Row className="detail-row">
          <Col lg={3}>
            <div className="d-flex margin-top-26">
              <div className="detail-div">Année</div>
              <div className="data-div ">{hits.year}</div>
            </div>
            <div className="d-flex margin-top-26">
              <div className="detail-div">Dévision</div>
              <div className="data-div ">{hits.category_age}</div>
            </div>
            <div className="d-flex margin-top-26">
              <div className="detail-div">Catégorie</div>
              <div className="data-div ">{hits.category_weight}</div>
            </div>
            <div className="d-flex margin-top-26">
              <div className="detail-div">Genre</div>
              <div className="data-div ">{hits.gender}</div>
            </div>
          </Col>
          <Col lg={3}>
            <div className="d-flex margin-top-26">
              <div className="detail-div">Niveau</div>
              <div className="data-div ">Grand Slam</div>
            </div>
            <div className="d-flex margin-top-26">
              <div className="detail-div">Arbitre</div>
              <div className="data-div ">{hits.referee}</div>
            </div>
            <div className="d-flex margin-top-26">
              <div className="detail-div">Table tour</div>
              <div className="data-div">{hits.table_tour}</div>
            </div>
            <div className="d-flex margin-top-26">
              <div className="detail-div">Nb de ségments</div>
              <div className="data-div">{detail.count}</div>
            </div>
          </Col>
          <Col lg={3}>
            <div className="d-flex margin-top-26">
              <div className="detail-div">Ne-Waza</div>
              <div className="data-div ">12</div>
            </div>
            <div className="d-flex margin-top-26">
              <div className="detail-div">Kumi-Kata</div>
              <div className="data-div ">10</div>
            </div>
          </Col>
        </Row>
        <Row className="d-flex-evently margin-top-20">
          <Col lg={4} className="detail-col">
            <div className="display-flex-between">
              <div className="m-r-25">
                <Image
                  src={photoProfile}
                  alt="profile"
                  className="image-style"
                ></Image>
              </div>
              <div className="width-74">
                <p className="color-name name-margin-top">
                  {hits.losing_fighter}
                </p>
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="d-flex">
                      <div className="detail-div w-20">Couleur</div>
                      <div className="data-div">{white_color() === country_looser() ? "Blanc":"Bleu"}</div>
                    </div>
                    <div className="d-flex">
                      <div className="detail-div w-20">Pays</div>
                      <div className="data-div">{country_looser()}</div>
                    </div>
                    <div className="d-flex">
                      <div className="detail-div w-20">Référence</div>
                      <div className="data-div">Référence</div>
                    </div>
                    <div className="d-flex">
                      <div className="detail-div w-20">Catégorie de Poids</div>
                      <div className="data-div">{hits.category_weight}</div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex">
                      <div className="detail-div w-20">Nb d'attaques</div>
                      <div className="data-div">{detail.good_point_loser}</div>
                    </div>
                    <div className="d-flex">
                      <div className="detail-div w-20">Réussies</div>
                      <div className="data-div">{detail.attaque_loser}</div>
                    </div>
                    <div className="d-flex">
                      <div className="detail-div w-20">
                        Efficience technique
                      </div>
                      <div className="data-div">
                        {percent(detail.attaque_loser, detail.good_point_loser)}
                        %
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="float-right">
                <img
                  src={vector}
                  alt="link"
                  className="image-cursor float-right"
                  onClick={() => goToCOmbat("looser", ids_loser)}
                />
              </div>
            </div>
            <div>
              <div className="margin-top-60 d-flex">
                <Col>
                  <div>
                    <div>Avant gauche</div>
                    {detail ? (
                      detail.list_schema?.map((item, index) => {
                        if (
                          item.direction === "AV G" &&
                          item.good_point === hits.losing_fighter
                        ) {
                          return (
                            <div className="d-flex" key={index}>
                              <div className="cercle-green"></div>
                              <div className="color-green-text">
                                {item.nega} ( {item.point} )
                              </div>
                            </div>
                          );
                        } else if (
                          item.direction === "AV G" &&
                          item.weak_point === hits.losing_fighter
                        ) {
                          return (
                            <div className="d-flex" key={index}>
                              <div className="cercle-red"></div>
                              <div className="color-red-text">
                                {item.nega} ( {item.point} )
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div>
                              <br />
                            </div>
                          );
                        }
                      })
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div>
                    <div>Arriére gauche</div>
                    {detail ? (
                      detail.list_schema?.map((item, index) => {
                        if (
                          item.direction === "AR G" &&
                          item.good_point === hits.losing_fighter
                        ) {
                          return (
                            <div className="d-flex" key={index}>
                              <div className="cercle-green"></div>
                              <div className="color-green-text">
                                {item.nega} ( {item.point} )
                              </div>
                            </div>
                          );
                        } else if (
                          item.direction === "AR G" &&
                          item.weak_point === hits.losing_fighter
                        ) {
                          return (
                            <div className="d-flex" key={index}>
                              <div className="cercle-red"></div>
                              <div className="color-red-text">
                                {item.nega} ( {item.point} )
                              </div>
                            </div>
                          );
                        } else {
                          return <br></br>;
                        }
                      })
                    ) : (
                      <div className="d-flex">
                        <div className="cercle-red"></div>
                        <div className="color-red-text"></div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col>
                  <Image src={cercle} alt="cercle" className="cercle"></Image>
                </Col>
                <Col>
                  <div>
                    <div>Avant droit</div>
                    {detail ? (
                      detail.list_schema?.map((item, index) => {
                        if (
                          item.direction === "AV D" &&
                          item.weak_point === hits.losing_fighter
                        ) {
                          return (
                            <div className="d-flex" key={index}>
                              <div className="cercle-red"></div>
                              <div className="color-red-text">
                                {item.nega} ( {item.point} )
                              </div>
                            </div>
                          );
                        } else if (
                          item.direction === "AV D" &&
                          item.good_point === hits.losing_fighter
                        ) {
                          return (
                            <div className="d-flex" key={index}>
                              <div className="cercle-green"></div>
                              <div className="color-green-text">
                                {item.nega} ( {item.point} )
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div>
                              <br />
                            </div>
                          );
                        }
                      })
                    ) : (
                      <div>
                        <br />
                      </div>
                    )}
                  </div>
                  <div>
                    <div>Arriére droit</div>
                    {detail ? (
                      detail.list_schema?.map((item, index) => {
                        if (
                          item.direction === "AR D" &&
                          item.good_point === hits.losing_fighter
                        ) {
                          return (
                            <div className="d-flex" key={index}>
                              <div className="cercle-green"></div>
                              <div className="color-green-text">
                                {item.nega} ( {item.point} )
                              </div>
                            </div>
                          );
                        } else if (
                          item.direction === "AR D" &&
                          item.weak_point === hits.losing_fighter
                        ) {
                          return (
                            <div className="d-flex" key={index}>
                              <div className="cercle-red"></div>
                              <div className="color-red-text">
                                {item.nega} ( {item.point} )
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div>
                              <br />
                            </div>
                          );
                        }
                      })
                    ) : (
                      <div className="cercle-green"></div>
                    )}
                  </div>
                </Col>
              </div>
            </div>
          </Col>
          <Col lg={4} className="detail-col">
            <div className="display-flex-between">
              <div className="m-r-25">
                <Image
                  src={photoProfile2}
                  alt="profile"
                  className="image-style"
                ></Image>
              </div>
              <div className="width-74">
                <p className="color-name name-title1">{hits.winner_fighter}</p>
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="d-flex">
                      <div className="detail-div w-20">Couleur</div>
                      <div className="data-div">{white_color() === country_winner() ? "Blanc":"Bleu"}</div>
                    </div>
                    <div className="d-flex">
                      <div className="detail-div w-20">Pays</div>
                      <div className="data-div">{country_winner()}</div>
                    </div>
                    <div className="d-flex">
                      <div className="detail-div w-20">Référence</div>
                      <div className="data-div">Référence</div>
                    </div>
                    <div className="d-flex">
                      <div className="detail-div w-20">Catégorie de Poids</div>
                      <div className="data-div">{hits.category_weight}</div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex">
                      <div className="detail-div w-20">Nb d'attaques</div>
                      <div className="data-div">{detail.good_point_winner}</div>
                    </div>
                    <div className="d-flex">
                      <div className="detail-div w-20">Réussies</div>
                      <div className="data-div">{detail.attaque_winner}</div>
                    </div>
                    <div className="d-flex">
                      <div className="detail-div w-20">
                        Efficience technique
                      </div>
                      <div className="data-div">
                        {percent(
                          detail.attaque_winner,
                          detail.good_point_winner
                        )}
                        %
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="float-right">
                <img
                  src={vector}
                  alt="link"
                  className="image-cursor float-right"
                  onClick={() => goToCOmbat("winner", id_winner)}
                />
              </div>
            </div>
            <div className="margin-top-60 d-flex">
              <Col>
                <div>
                  <div>Avant gauche</div>
                  {detail ? (
                    detail.list_schema?.map((item, index) => {
                      if (
                        item.direction === "AV G" &&
                        item.good_point === hits.winner_fighter
                      ) {
                        return (
                          <div className="d-flex" key={index}>
                            <div className="cercle-green"></div>
                            <div className="color-green-text">
                              {item.nega} ( {item.point} )
                            </div>
                          </div>
                        );
                      } else if (
                        item.direction === "AV G" &&
                        item.good_point === hits.winner_fighter
                      ) {
                        return (
                          <div className="d-flex" key={index}>
                            <div className="cercle-red"></div>
                            <div className="color-red-text">
                              {item.nega} ( {item.point} )
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            <br />
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div className="cercle-green"></div>
                  )}
                </div>
                <div>
                  <div>Arriére gauche</div>
                  {detail ? (
                    detail.list_schema?.map((item, index) => {
                      if (
                        item.direction === "AR G" &&
                        item.good_point === hits.winner_fighter
                      ) {
                        return (
                          <div className="d-flex" key={index}>
                            <div className="cercle-green"></div>
                            <div className="color-green-text">
                              {item.nega} ( {item.point} )
                            </div>
                          </div>
                        );
                      } else if (
                        item.direction === "AR G" &&
                        item.weak_point === hits.winner_fighter
                      ) {
                        return (
                          <div className="d-flex" key={index}>
                            <div className="cercle-red"></div>
                            <div className="color-red-text">
                              {item.nega} ( {item.point} )
                            </div>
                          </div>
                        );
                      } else {
                        return <div></div>;
                      }
                    })
                  ) : (
                    <div className="cercle-green"></div>
                  )}
                </div>
              </Col>
              <Col>
                <Image src={cercle} alt="cercle" className="cercle"></Image>
              </Col>
              <Col>
                <div>
                  <div>Avant droit</div>
                  {detail ? (
                    detail.list_schema?.map((item, index) => {
                      if (
                        item.direction === "AV D" &&
                        item.good_point === hits.winner_fighter
                      ) {
                        return (
                          <div className="d-flex" key={index}>
                            <div className="cercle-green"></div>
                            <div className="color-green-text">
                              {item.nega} ( {item.point} )
                            </div>
                          </div>
                        );
                      } else if (
                        item.direction === "AV D" &&
                        item.weak_point === hits.winner_fighter
                      ) {
                        return (
                          <div className="d-flex" key={index}>
                            <div className="cercle-red"></div>
                            <div className="color-red-text">
                              {item.nega} ( {item.point} )
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            <br></br>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div>
                      <br />
                    </div>
                  )}
                </div>
                <div>
                  <div>Arriére droit</div>
                  {detail ? (
                    detail.list_schema?.map((item, index) => {
                      if (
                        item.direction === "AR D" &&
                        item.good_point === hits.winner_fighter
                      ) {
                        return (
                          <div className="d-flex" key={index}>
                            <div className="cercle-green"></div>
                            <div className="color-green-text">
                              {item.nega} ( {item.point} )
                            </div>
                          </div>
                        );
                      } else if (
                        item.direction === "AR D" &&
                        item.weak_point === hits.winner_fighter
                      ) {
                        return (
                          <div className="d-flex" key={index}>
                            <div className="cercle-red"></div>
                            <div className="color-red-text">
                              {item.nega} ( {item.point} )
                            </div>
                          </div>
                        );
                      } else {
                        return <div></div>;
                      }
                    })
                  ) : (
                    <div className="cercle-green"></div>
                  )}
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        <Row className="margin-top-20">
          <div className="d-flex-between table_match">
            <div className="m-l">combattants</div>
            <div className="m-l">sequence</div>
            <div className="m-l">
              {hits.losing_fighter ? getInitials(hits.losing_fighter) : "A.B"}{" "}
              vs{" "}
              <span className="name-title1">
                {hits.winner_fighter ? getInitials(hits.winner_fighter) : "C.D"}
              </span>
              :
            </div>
            <div className="display-flex-1 m-l">
              {point
                ? point.map((item, index) => {
                    if ((item === "0.0") | (item === "0") | (item === "nan")) {
                      return (
                        <div key={index}>
                          <div className="white-color"></div>
                        </div>
                      );
                    } else if (
                      (item === "1.0") |
                      (item === "1") |
                      (item === "10") |
                      (item === "10.0")
                    ) {
                      return (
                        <div key={index}>
                          <div className="green-color"></div>
                        </div>
                      );
                    } else if (item === "Kinsa") {
                      return (
                        <div key={index}>
                          <div className="gray-color"></div>
                        </div>
                      );
                    } else return <div key={index}></div>;
                  })
                : null}
            </div>
          </div>
        </Row>
      </div>
    );
  }
  return (
    <>
      <SideBar></SideBar>
      {Detail()}
    </>
  );
}
